import { mapHelper } from "@/utils/common.js";

// 是否自主
const isOwners = [
  {
    value: "0",
    label: "否",
  },
  {
    value: "1",
    label: "是",
  },
];

const { map: isOwnersMap, setOps: isOwnersOps } = mapHelper.setMap(isOwners);
//房屋用途
const usage = [
  {
    value: "0",
    label: "空关",
  },
  {
    value: "1",
    label: "自主",
  },
  {
    value: "2",
    label: "出租",
  },
  {
    value: "3",
    label: "群租",
  },
];

const { map: usageMap, setOps: usageOps } = mapHelper.setMap(usage);
// 人户关系
const relation = [
  {
    value: "0",
    label: "未知",
  },
  {
    value: "1",
    label: "人户一致",
  },
  {
    value: "2",
    label: "人在户不在",
  },
  {
    value: "3",
    label: "户在人不在",
  },
  {
    value: "4",
    label: "流动人口",
  },
  {
    value: "5",
    label: "境外",
  },
  {
    value: "6",
    label: "集体户",
  },
];

const { map: relationMap, setOps: relationOps } = mapHelper.setMap(relation);
//户籍人口
const registeredPopulation = [
  {
    value: "0",
    label: "不详",
  },
  {
    value: "1",
    label: "常口",
  },
];
const { map: registeredPopulationMap, setOps: registeredPopulationOps } =
  mapHelper.setMap(registeredPopulation);
//上报状态
const reportStatus = [
  {
    value: 1,
    label: "成功",
  },
  {
    value: 2,
    label: "失败",
  },
 
];
const { map: reportStatusMap, setOps: reportStatusOps } =
  mapHelper.setMap(reportStatus);
//更新状态
const updateStatus = [
  {
    value: 0,
    label: "未更新",
  },
  {
    value: 1,
    label: "已更新",
  },
];
const { map: updateStatusMap, setOps: updateStatusOps } =
  mapHelper.setMap(updateStatus);
//身份证类型
const cardType = [
  {
    value: "2000",
    label: "居民身份证",
  },
  {
    value: "2002",
    label: "护照",
  },
  {
    value: "2005",
    label: "港澳台同胞来往通行证",
  },
  {
    value: "2099",
    label: "其他",
  },
];
const { map: cardTypeMap, setOps: cardTypeOps } = mapHelper.setMap(cardType);
//是否上报
const isReportedStatus = [
  {
    value: 0,
    label: "未上报",
  },
  {
    value: 1,
    label: "已上报",
  },
  
];
const { map: isReportedStatusMap, setOps: isReportedStatusOps } = mapHelper.setMap(isReportedStatus);
export {
  isOwnersOps,
  isOwnersMap,
  usageOps,
  usageMap,
  relationOps,
  registeredPopulationOps,
  reportStatusOps,
  updateStatusOps,
  cardTypeOps,
  cardTypeMap,
  reportStatusMap,
  isReportedStatusOps
};
