var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"EditResidentInfo"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"真实姓名","rules":[
        { required: true, message: '请输入真实姓名', trigger: 'blur' },
      ],"prop":"realName"}},[_c('v-input',{attrs:{"width":_vm.width,"placeholder":"请输入真实姓名"},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1),_c('el-form-item',{attrs:{"label":"手机号码","rules":[
        { required: true, message: '请输入手机号码', trigger: 'blur' },
      ],"prop":"phone"}},[_c('v-input',{attrs:{"width":_vm.width,"disabled":_vm.isReadonlyPhone,"placeholder":"请输入手机号码"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('v-button',{staticClass:"btn",attrs:{"text":"变更"},on:{"click":function($event){_vm.isReadonlyPhone = false}}})],1),_c('el-form-item',{attrs:{"label":"证件类型","prop":"cardType","rules":[
        { required: true, message: '请选择证件类型', trigger: 'blur' },
      ]}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.cardTypeOps},model:{value:(_vm.form.cardType),callback:function ($$v) {_vm.$set(_vm.form, "cardType", $$v)},expression:"form.cardType"}})],1),_c('el-form-item',{attrs:{"label":"证件号","prop":"cardNum","rules":[{ required: true, message: '请输入证件号', trigger: 'blur' }]}},[_c('v-input',{attrs:{"width":_vm.width,"disabled":_vm.isReadonlyCardNum,"placeholder":"请输入证件号"},model:{value:(_vm.form.cardNum),callback:function ($$v) {_vm.$set(_vm.form, "cardNum", $$v)},expression:"form.cardNum"}}),_c('v-button',{staticClass:"btn",attrs:{"text":"变更"},on:{"click":function($event){_vm.isReadonlyCardNum = false}}})],1),_c('el-form-item',{attrs:{"label":"是否自主","prop":"isOwner","rules":[
        { required: true, message: '请选择是否自主', trigger: 'blur' },
      ]}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.isOwnersOps},model:{value:(_vm.form.isOwner),callback:function ($$v) {_vm.$set(_vm.form, "isOwner", $$v)},expression:"form.isOwner"}})],1),_c('el-form-item',{attrs:{"label":"户籍人口","prop":"isCensusRegister","rules":[
        { required: true, message: '请选择户籍人口', trigger: 'blur' },
      ]}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.registeredPopulationOps},model:{value:(_vm.form.isCensusRegister),callback:function ($$v) {_vm.$set(_vm.form, "isCensusRegister", $$v)},expression:"form.isCensusRegister"}})],1),_c('el-form-item',{attrs:{"label":"房号选择","prop":"houseId","rules":[{ required: true, message: '请选择房号', trigger: 'blur' }]}},[_c('v-cascader',{attrs:{"width":_vm.width,"placeholder":"默认表示全部","options":_vm.sectionOps,"props":_vm.props},on:{"change":_vm.sectionChange},model:{value:(_vm.authSpaceIds),callback:function ($$v) {_vm.authSpaceIds=$$v},expression:"authSpaceIds"}})],1),_c('el-form-item',{attrs:{"label":"省平台账户ID ","prop":"loginId"}},[_c('v-input',{attrs:{"disabled":_vm.isReadonlyLoginId,"width":_vm.width},model:{value:(_vm.form.loginId),callback:function ($$v) {_vm.$set(_vm.form, "loginId", $$v)},expression:"form.loginId"}}),_c('v-button',{staticClass:"btn",attrs:{"text":"变更"},on:{"click":function($event){_vm.isReadonlyLoginId = false}}})],1),_c('el-form-item',{attrs:{"label":"省平台房屋ID ","prop":"rfid"}},[_c('v-input',{attrs:{"disabled":_vm.isReadonlyRfid,"width":_vm.width},model:{value:(_vm.form.rfid),callback:function ($$v) {_vm.$set(_vm.form, "rfid", $$v)},expression:"form.rfid"}}),_c('v-button',{staticClass:"btn",attrs:{"text":"变更"},on:{"click":function($event){_vm.isReadonlyRfid = false}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }