//列表接口
const getQueryPdfUserList = `/gateway/hc-portal/import/queryPdfUserList`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
//详情
const getPdfUser = `/gateway/hc-portal/import/getPdfUser`;
//提交
const submitPdfUser = `/gateway/hc-portal/import/submitPdfUser`;
//证件类型
const getCardType = `/gateway/hc-portal/szdz/dictList`;
//获取上报/未上报/上报失败的人员数据
const getReportedUserInfoList = `/gateway/hc-portal/szdz/zzt/getReportedUserInfoList`;
//人员信息详情
const getUserContrastDetails = `/gateway/hc-portal/szdz/zzt/getUserContrastDetails`;
//取消人房信息上报/取消并删除信息
const cancelReportedUserConInfo = `/gateway/hc-portal/szdz/zzt/cancelReportedUserConInfo`;
//编辑并上报人房关系
const updateAndReport = `/gateway/hc-portal/szdz/zzt/updateAndReport`;
//上报人员信息/批量
const reportedPeopleHouse = `/gateway/hc-portal/szdz/zzt/reportedPeopleHouse`;
//查看上报错误日志
const getReportedErrorLog = `/gateway/hc-portal/szdz/zzt/getReportedErrorLog`;

export {
  getQueryPdfUserList,
  getBuildListURL,
  getPdfUser,
  submitPdfUser,
  getCardType,
  getReportedUserInfoList,
  getUserContrastDetails,
  cancelReportedUserConInfo,
  updateAndReport,
  reportedPeopleHouse,
  getReportedErrorLog
};
